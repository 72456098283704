import logo from './logo.svg';
import './App.css';
import RoundedBlueContainer from "../src/componets/roundedBlueContainer/RoundedBlueContainer";
import RoundedPinkContainer from "../src/componets/roundedPinkContainer/RoundedPinkContainer";
import Header from "../src/componets/header/Header"
import Catalog from "../src/componets/Catalog/Catalog";
import About from "../src/componets/About/About";
import Footer from "../src/componets/footer/Footer";
import Order from "../src/componets/order/Order";
import OnePlace from "../src/componets/onePlace/OnePlace"
import Reviews from "../src/componets/reviews/Reviews";
import Navbar from "../src/componets/navbar/Navbar";
import { Modal } from '../src/componets/modal/modal';

function App() {
  return (
    <div className="App">
      <RoundedPinkContainer>
        <RoundedBlueContainer>
          <Header />
          <Navbar />
          <Catalog />
          <About />
        </RoundedBlueContainer>
        <Order />
        <OnePlace />
        <Reviews />
      </RoundedPinkContainer>
      <Footer />
      <Modal title="Данные отправлены успешно"/>
    </div>
  );
}

export default App;
