import React, { useState, useEffect } from "react"
import GoogleApi from "../TextField/Controller/GoogleApi"
import "./send-button.css"
import { clear } from "@testing-library/user-event/dist/clear.js"
// import { setModalVisible, setModalInvisible } from "../components/modal.js"

const SendButton = (props) => {
    let data = props.data
    const onError = (props.onError !== undefined) ? props.onError : (el) => {}
    const onClick = (props.onClick !== undefined) ? props.onClick : () => {}
    const url = props.url
    const spreadsheet = props.spreadsheet
    const [dataIsLoading, setLoading] = useState(false)

    const hideModal = () => {
        const modal = document.querySelector('.modal__container')
        const root = document.querySelector('#root')
        let opacity = 1
        function anim(){
            modal.style.opacity = opacity
            opacity -= 0.1
            if (opacity <= 0) {
                clearInterval(interval)
                modal.style.display = "none"
                root.style.overflowY = "unset"
                document.body.style.overflow = "unset"
            }
        }
        let interval = setInterval(anim, 10)
    }

    const showModal = () => {
        const y = window.scrollY
        const top = document.querySelector(".send-button__container").getBoundingClientRect().top
        const modal = document.querySelector('.modal__container')
        const content = document.querySelector('.modal__content-container')
        const root = document.querySelector('#root')
        root.style.overflow = "hidden"
        modal.style.overflow = "hidden"
        document.body.style.overflow = "hidden"
        modal.style.opacity = 1
        modal.style.display = "unset"
        modal.style.top = "0"
        content.style.top = `${y}px`

        setTimeout(() => {
            // hideModal()
        }, 2000)
    }

    useEffect(() => {
        // showModal()
    }, [])

    const sendData = async (e) => {
        let hasError = false

        let keys = Object.keys(data)
        let row = ["Залупа Иванычччч", "8 800 555 35 35"]

        let name = document.querySelector('.Order_input_name')
        let phone =  document.querySelector('.Order_phone')


        onClick()
        showModal()
        setTimeout(() => {
            hideModal()
        }, 3000)

        if (spreadsheet === undefined || String(spreadsheet).length == 0) {
            console.log("error 2")
            return
        }

        if (data === undefined) {
            console.log("error 3")
            return
        }
        const worker = new GoogleApi(spreadsheet)
        setLoading(true)
        const res = await worker.postRow(row)
        setLoading(false)
        return res
    }

    return (
        <div className="send-button__container">
            <div className="send-button">
                <button className="send-button" onClick={sendData}>Отправить</button>
            </div>
        </div>
        
    )
}

export default SendButton